import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Cta from '../../components/cta'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const LuxusniProceduryPage = ({ data }) => (
  <Layout>
    <SEO title="Služby masážního salonu Vimperk" />

    <div className="container">
      <h1 className="pb-1">
        Luxusní procedury z&nbsp;Fiji a&nbsp;ostrovní rituály
      </h1>

      <Img fluid={data.hero.childImageSharp.fluid} objectFit="cover" style={{maxWidth: '960px', margin: 'auto'}} title="zdroj: purefiji.cz" />

      <p>
        Na začátku našeho sezení probereme Vaše potřeby a&nbsp;z&nbsp;více než
        30&nbsp;procedur vytvoříme kombinaci právě pro&nbsp;Vás.
        <br />
        Doporučujeme vyhradit&nbsp;si čas aspoň na&nbsp;hodinovou masáž.
      </p>

      <div className="uk-grid uk-child-width-expand pb-2">
        <div>
          <h3>Afrodita s koupelí</h3>

          <p>
            Dvou a půlhodinová procedura začínající koupelí ve vaně s peelingem
            a kokosovým mlékem s vůní květin z Oceánie. Následuje masáž celého
            těla klientky s antistresovou masáží hlavy, a dalších částí těla dle
            přání.
          </p>

          <p className="price">2 500 Kč / 2&nbsp;hodiny a&nbsp;30&nbsp;minut</p>

          <h3>Čokoládové pokušení</h3>

          <p>Peeling celého těla, čokoládový zábal a čokoládová masáž</p>

          <p className="price">2&nbsp;000&nbsp;Kč / 2&nbsp;hodiny</p>

          <h3>Další procedury</h3>

          {/* <ul>
            <li>Regenerační zábal rukou,</li>
            <li>tropický zábal nohou,</li>
            <li>zábal rukou pro&nbsp;muže,</li>
            <li>zábal nohou pro&nbsp;muže.</li>
          </ul>

          <p className="price">500 Kč / proceduru</p> */}

          <ul>
            <li>Relaxační kokosová masáž s&nbsp;peelingem,</li>
            <li>relaxační mango masáž s&nbsp;peelingem,</li>
            <li>Fijijská anistresová masáž hlavy pro&nbsp;muže,</li>
            <li>medová masáž Fiji.</li>
          </ul>

          <p className="price">1 100 Kč / 1&nbsp;hodinu</p>

          {/* <h3>Cukrový vánek z ráje</h3> */}

          {/* <h3>Fiji rituál</h3> */}
        </div>
        <div>
          <Img fluid={data.fiji.childImageSharp.fluid} objectFit="cover" />
        </div>
      </div>

      {/* <a className="btn-cta" href="tel:+420776009508">
        Volejte 776 009 508
      </a> */}
      <Cta before={<Link className="link" to="/sluzby">Zpět na všechny služby</Link>} />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    fiji: file(relativePath: { eq: "fiji.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero: file(relativePath: { eq: "body.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default LuxusniProceduryPage
